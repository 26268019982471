import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  { path: '', redirectTo: '/guides/1', pathMatch: 'full' },
  { 
    path: 'checklist',
    loadChildren: () => import('./checklist/checklist.module').then(m => m.ChecklistModule),
    canActivate: [MsalGuard]
  },
  { 
    path: 'organizations',
    loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule),
    canActivate: [MsalGuard]
  },
  { 
    path: 'edit',
    loadChildren: () => import('./edit/edit.module').then(m => m.EditModule),
    canActivate: [MsalGuard]
  },
  { 
    path: 'onboard', redirectTo: '/guides/1', pathMatch: 'full',
  },
  { 
    path: 'organizations/:id/groups',
    loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
    canActivate: [MsalGuard]
  },
  { 
    path: 'guides/:id',
    loadChildren: () => import('./guide/guide.module').then(m => m.GuideModule),
    canActivate: [MsalGuard]
  },
  { path: '**', redirectTo: '/organizations', pathMatch: 'full', }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
   initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
