<mat-toolbar class="toolbar mat-elevation-z8">
  <img class="logo" routerLink="/" src="/assets/img/onboard-logo.png" />
  <!-- <a class="nav-link" routerLinkActive="active" routerLink="/checklist">Checklist</a> -->
  <a class="nav-link" routerLinkActive="active" routerLink="/guides/{{ this.guideId }}">Onboard Guide</a>
  <ng-template [ngIf]="this.canEdit === true">
    <button mat-button extended [matMenuTriggerFor]="menu" class="nav-button-menu">
      Edit Site<mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </ng-template>
  <mat-menu #menu="matMenu" class="edit-site-dropdown">
    <a class="link-option" routerLinkActive="active" routerLink="/edit/guides/{{ this.guideId }}">Onboard Guide</a>
    <a class="link-option" routerLinkActive="active" routerLink="/edit/checklist">Checklist Page</a>
  </mat-menu>
  <ng-template [ngIf]="this.isProd === false">
    <label class="non-prod-label">NON-PRODUCTION</label>
  </ng-template>
  <div class="spacer"></div>
  <mat-form-field class="search" appearance="fill">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      class="search-input"
      [formControl]="searchTerm"
      placeholder="Search..."
    />
  </mat-form-field>
  <img class="logo" src="/assets/img/TeamTransparentBG.svg" />
</mat-toolbar>
<app-search-results [searchTerm]="searchTerm" [searchResults]="searchResults"></app-search-results>
