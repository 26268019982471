import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  public guideId = new BehaviorSubject(undefined);
}
