import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { RoutesService } from '../../services/routes.service';
import { environment } from '../../../../environments/environment';
import { FormControl } from '@angular/forms';
import { GuideService } from '../../services/guide.service';
import { SearchResultDTO } from '../../../models/searchResultDTO';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  guideId: number;
  canEdit: boolean = false;
  isProd: boolean = environment.production;
  searchTerm = new FormControl('');
  searchResults: SearchResultDTO[];
  private readonly destroying$ = new Subject<void>();

  constructor(private aRoute: ActivatedRoute, private routeSvc: RoutesService, private guide: GuideService) {}

  ngOnInit(): void {
    this.aRoute?.queryParamMap?.pipe(filter((params) => params?.has('edit'))).subscribe((params) => {
      this.canEdit = params?.get('edit') === 'true';
    });

    this.routeSvc.guideId.subscribe((gId) => (this.guideId = gId));

    this.searchTerm.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroying$),
        switchMap((changedValue) => this.doSearch(changedValue))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroying$.complete();
  }

  toggleEditMode(): void {
    this.canEdit = !this.canEdit;
  }

  async doSearch(searchTeam: string): Promise<void> {
    if (!searchTeam) {
      this.searchResults = [];
    } else {
      this.guide?.searchGuide(this.guideId, searchTeam).subscribe((data) => {
        this.searchResults = data;
      });
    }
  }
}
