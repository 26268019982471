@if(showOptions===true){
<div class="table-container" (click)="onClose()">
  <div class="table-options">
    <div class="options-container">
      <div class="creation-options">
        <button
          matTooltip="Insert table"
          mat-button
          class="add-table table-button"
          (click)="tableModule.insertTable(1, 2)"
        >
          <img src="/assets/img/table-add.svg" />
        </button>
        <button
          mat-button
          matTooltip="Delete table"
          class="delete-table table-button"
          (click)="tableModule.deleteTable()"
        >
          <img src="/assets/img/table-delete.svg" />
        </button>
      </div>
      <div class="row-column-options">
        <button
          mat-button
          matTooltip="Insert row above"
          class="table-button option"
          (click)="tableModule.insertRowAbove()"
        >
          <img src="/assets/img/table-add-row-above.svg" />
        </button>
        <button
          mat-button
          matTooltip="Insert row below"
          class="table-button option"
          (click)="tableModule.insertRowBelow()"
        >
          <img src="/assets/img/table-add-row-below.svg" />
        </button>

        <button
          mat-button
          matTooltip="Insert column left"
          class="table-button option"
          (click)="tableModule.insertColumnLeft()"
        >
          <img src="/assets/img/table-add-column-left.svg" />
        </button>
        <button
          mat-button
          matTooltip="Insert column right"
          class="table-button option"
          (click)="tableModule.insertColumnRight()"
        >
          <img src="/assets/img/table-add-column-right.svg" />
        </button>
      </div>

      <div class="row-column-options">
        <button mat-button matTooltip="Delete row" class="table-button option" (click)="tableModule.deleteRow()">
          <img src="/assets/img/delete-row.svg" />
        </button>
        <button mat-button matTooltip="Delete column" class="table-button option" (click)="tableModule.deleteColumn()">
          <img src="/assets/img/delete-column.svg" />
        </button>
      </div>
    </div>
  </div>
</div>
}
