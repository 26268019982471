import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Group } from '../../models/group';
import { EntityData } from '../../models/entityData';
import { GroupsService } from '../../shared/services/groups.service';

@Component({
  selector: 'app-groups-view',
  templateUrl: './groups-view.component.html',
  styleUrls: ['./groups-view.component.scss']
})
export class GroupsViewComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  public organizationId: number;
  public groupsData!: Group[];
  public entities: EntityData[];
  public hasData: boolean = true;
  public isLoadingData: boolean = true;

  constructor(private groupsService: GroupsService, private route: ActivatedRoute, private msalBroadcastService: MsalBroadcastService) {}

  ngOnInit(): void {
    this.hasData = true;
    this.isLoadingData = true;

    this.subs.push(
      this.route.params.subscribe(params => {
        this.organizationId = params['id'];

        this.subs.push(
          this.groupsService.loadGroups(this.organizationId).subscribe((data) => {
            this.groupsData = data;
            this.entities = this.groupsData.map((group) => ({
                id: group.groupId,
                name: group.name,
                route: `/guides/${group.groupId}`
              }));
            
            this.hasData = (this.groupsData != null && this.groupsData.length > 0);
            this.isLoadingData = false;
          }, (error) => {
            console.log(error);
            this.groupsData = null;
            this.hasData = false;
            this.isLoadingData = false;
          })
        );
      })
    );

    this.subs.push(this.msalBroadcastService?.msalSubject$
      ?.pipe(filter((msg: EventMessage) => msg?.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {}));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
