import { Component, OnInit } from '@angular/core';import { ActivatedRoute } from '@angular/router';

import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';

import { filter } from 'rxjs/operators';

import { Organization } from '../../models/organization';
import { EntityData } from '../../models/entityData';
import { OrganizationsService } from '../../shared/services/organizations.service';


@Component({
  selector: 'app-organizations-view',
  templateUrl: './organizations-view.component.html',
  styleUrls: ['./organizations-view.component.scss']
})
export class OrganizationsViewComponent implements OnInit {

  public organizationsData!: Organization[];
  public entities: EntityData[];

  constructor(private organizationsService: OrganizationsService, private route: ActivatedRoute, private msalBroadcastService: MsalBroadcastService) {}

  ngOnInit(): void {    
    this.organizationsService?.loadOrganizations()?.subscribe((data) => {
      this.organizationsData = data;
      
      this.entities = this.organizationsData.map((organization) => ({
          id: organization.OrganizationId,
          name: organization.Name,
          route: `/organizations/${organization.OrganizationId}/groups`
        }));
    });

    this.msalBroadcastService?.msalSubject$
      ?.pipe(filter((msg: EventMessage) => msg?.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {});
  }
}
