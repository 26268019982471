import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Organization } from '../../models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  constructor(private http: HttpClient) {}

  loadOrganizations(): Observable<Organization[]> {
    let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' });

    return this.http
      .get<Organization[]>(`${environment?.apiEndpoints?.onboardingApi}/v1/organization`, {
        headers: headers
      })
      .pipe(
        map((organizations) => organizations.map((organization) => ({
          ...organization,          
          OrganizationId: organization.OrganizationId,
          OrganizationName: organization.Name
        })))
      );
  }

  saveOrganization(body: Organization) {
    this.http.post(`${environment?.apiEndpoints?.onboardingApi}/v1/organization`, body).subscribe();
  }
}