import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

export interface toastPayload {
  message: string;
  title: string;
  config: IndividualConfig;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastr: ToastrService) {
    this.toastr.toastrConfig.enableHtml = true;
  }
  showToast(toast: toastPayload) {
    this.toastr.show(toast.message, toast.title, toast.config, `toast-${toast.type}`);
  }
}
