import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Guide } from '../../models/guide';
import { GuideDTO } from 'src/app/models/guideDTO';
import { RoutesService } from './routes.service';
import { SearchResultDTO } from '../../models/searchResultDTO';

@Injectable({
  providedIn: 'root'
})
export class GuideService {
  constructor(private http: HttpClient, private routeSvc: RoutesService) {}

  loadGuide(guideId: number): Observable<Guide> {
    let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' });

    this.routeSvc.guideId.next(guideId);

    return this.http
      .get<GuideDTO>(`${environment?.apiEndpoints?.onboardingApi}/v1/guide/${guideId}`, {
        headers: headers
      })
      .pipe(
        map((guide) => ({
          guideId: guide.GuideId,
          groupId: guide.GroupId,
          title: guide.Title,
          sections: guide.sections.map((section) => ({
            ...section,
            guideSectionId: section.GuideSectionId,
            guideId: section.GuideId,
            title: section.title,
            content: section.content
          }))
        }))
      );
  }

  saveGuide(body: Guide): Observable<Guide> {
    let guideDTO: GuideDTO = ({
      GuideId: body.guideId,
      GroupId: body.groupId,
      Title: body.title,
      sections: body.sections.map((section) => ({
        ...section,
        GuideSectionId: section.guideSectionId,
        GuideId: section.guideId,
        title: section.title,
        content: section.content
      }))
    });

    return this.http.put<Guide>(`${environment?.apiEndpoints?.onboardingApi}/v1/guide`, guideDTO);
  }

  searchGuide(guideId: number, searchTerm: string) : Observable<SearchResultDTO[]>{
    let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' });
    return this.http.get<SearchResultDTO[]>(`${environment?.apiEndpoints?.onboardingApi}/v1/guide/${guideId}/search/${searchTerm}`, {
      headers: headers
    })
  }
}
