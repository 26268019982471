import { Component, OnInit, ViewChildren, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GuideService } from '../../shared/services/guide.service';
import { Guide } from '../../models/guide';
import { Section } from '../../models/section';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService, toastPayload } from '../../shared/services/notification.service';
import { IndividualConfig } from 'ngx-toastr';
import { UnsavedSectionChanges } from 'src/app/models/unsaved-section-changes';
import { ExpansionPanelComponent } from '../expansionPanel/expansionPanel.component';
import { ChangeType } from '../../models/changeType';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/guard/pending-changes.guard';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-view',
  templateUrl: './edit-view.component.html',
  styleUrls: ['./edit-view.component.scss']
})
export class EditViewComponent implements OnInit, ComponentCanDeactivate {
  public guideId: number;
  guideData!: Guide;
  errorMessage: string = '';
  public unsavedChanges: boolean = false;
  public hasData: boolean = true;
  private toast!: toastPayload;
  public isSaving: boolean = false;

  @ViewChildren(ExpansionPanelComponent) private expansionPanels: Array<ExpansionPanelComponent>;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // If there are unsaved changes, then the page should trigger a message so canDeactivate is false.
    return !this.unsavedChanges;
  }

  constructor(private guide: GuideService, public dialog: MatDialog, private notifier: NotificationService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.hasData = true;
    this.route.params.subscribe(params => {
      this.guideId = params['id'];

      this.guide?.loadGuide(this.guideId)?.subscribe({
        next: data => this.guideData = data, 
        error: error => {
          console.log(error);
          this.guideData = null;
          this.hasData = false;        
        }
      })
    });
  }

  dropped(event: CdkDragDrop<Section[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.guideData.sections, event.previousIndex, event.currentIndex);
      const ids = this.guideData.sections.map(section => section.guideSectionId)
      ids.sort((a:number, b:number) => a - b)
      const sections   = this.guideData.sections.map((value, idx) => Object.assign({}, value, {guideSectionId: ids[idx], GuideSectionId: ids[idx]}))
      this.guideData.sections = sections
      this.unsavedChanges = true;
    }
  }

  /*
   * When changes are made to the different sections, then we need to update the object to send to the API upon clicking on Save Changes
   *
   * TODO: In the future we should not be sending the entire Guide back to the back end, we should only be sending the sections that have been touched.
   */
  changesMade(changes: UnsavedSectionChanges) {
    // To show that changes have been made, set unsavedChanges to true
    this.unsavedChanges = true;
    
    // Find the actual section that is being edited in the original guide
    const sectionToUpdate = this.guideData.sections.find(
      (section: Section) => section.guideSectionId === changes.guideSectionId
    );

    // Update the _remove variable to whatever is coming back in the unsaved changes object
    // This should handle cases where the remove button has been toggled.
    sectionToUpdate._remove = changes.remove;

    // If we aren't removing the object, we should update the changes made
    if (!sectionToUpdate._remove) {
      sectionToUpdate.content = changes.content;
      sectionToUpdate.title = changes.title;
    }
  }

  addNewSection(): void {
    let newSection: any = {
      guideId: this.guideData.guideId,
      guideSectionId: 0,
      groupId: this.guideData.groupId,
      sequence: 0,
      content: 'Content',
      title: 'New Section',
      _remove: false
    };
    this.guideData.sections.push(newSection);
    this.unsavedChanges = true;
  }

  saveGuide() {
    this.isSaving = true;
    // This will take out any pending removals to send to the back end.
    this.guideData.sections = this.guideData.sections.filter((section: Section) => !section._remove);

    this.guide.saveGuide(this.guideData)
    .subscribe({
      next: (res) => {
        this.unsavedChanges = false;
        this.showToastMessage('success', '<h3>Saved successfully.</h3>');
      },
      error: (error) => {
        this.showToastMessage('error', '<h3>Encountered an error when saving. Try to save again.</h3>');
      },
      complete: () => {
        this.isSaving = false;
        this.expansionPanels.forEach((expansionPanelComponent) => {
          expansionPanelComponent.componentChangesClass = ChangeType.save;
        });
      }
    });    
  }

  showToastMessage(type: string, message: string) {
    this.toast = {
      message: message,
      title: '',
      type: type,
      config: {
        timeOut: 2500,
        positionClass: 'toast-bottom-right',
        closeButton: true
      } as IndividualConfig
    };
    this.notifier.showToast(this.toast);
  }
}
