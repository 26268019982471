<ng-container *ngIf="searchResults?.length > 0">
  <div ng-bind-html-unsafe="searchResults" class="search-results">
    <span (click)="searchTerm.reset()" class="material-icons close">close</span>
    <p *ngIf="searchTerm.value" class="results">Results for "<span [innerHTML]="searchTerm.value"></span>"</p>
    <div class="search-result-cards">
      <div (click)="this.doNavigation(item)" class="result-card" *ngFor="let item of searchResults">
        <span class="material-icons">content_paste_search</span>
        <div class="search-content">
          <h1 [innerHTML]="item.title"></h1>
          <p [innerHTML]="item.text_search_headline"></p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
