import { Component, Input, OnInit } from '@angular/core';
import { SearchResultDTO } from '../../../models/searchResultDTO';
import { GuideRoutingModule } from '../../../guide/guide-routing.module';
import { SectionDataService } from '../../../service/SectionDataService.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  providers: [GuideRoutingModule]
})
export class SearchResultsComponent {
  @Input() searchTerm: FormControl<string>;
  @Input() searchResults: SearchResultDTO[];

  constructor(private sectionDataService: SectionDataService) {}

  doNavigation(item: SearchResultDTO): void {
    this.sectionDataService.serviceData.next(item.guide_section_id);
  }
}
