import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeWhile } from 'rxjs';
import { ErrorDialogService } from '../../services/error.dialog.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @ViewChild('notificationContainer') container: ElementRef<HTMLDivElement>;
  message: string;
  title: string;
  description: string;
  public open: boolean = false;
  panelOpenState = false;
  private _subscribed: boolean = true;
  constructor(private errorDialog: ErrorDialogService) {}
  ngOnInit(): void {
    this.errorDialog.notification.pipe(takeWhile(() => this._subscribed)).subscribe((notification) => {
      if (notification?.message) {
        this.open = true;
        this.message = notification.message;
        this.title = notification.title;
        this.description = notification.description;
      } else {
        this.open = false;
      }
    });
  }
  public handleCloseModal = () => {
    this.open = false;
  };
  public handleCopyToClipboard = ({ target }: MouseEvent) => {
    navigator.clipboard.writeText((target as HTMLElement).innerHTML || '');
  };
}
