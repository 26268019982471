import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';

import { filter, takeUntil } from 'rxjs/operators';
import { Section } from 'src/app/models/section';

import { Guide } from '../../models/guide';
import { GuideService } from '../../shared/services/guide.service';
import { SectionDataService } from '../../service/SectionDataService.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-guide-view',
  templateUrl: './guide-view.component.html',
  styleUrls: ['./guide-view.component.scss']
})
export class GuideViewComponent implements OnInit {
  guideId: any;
  public guideData!: Guide;
  public selectedSection!: Section;
  public selectedSectionId!: number;
  public searchSection: number;
  public hasData = true;
  private readonly destroying$ = new Subject<void>();

  constructor(
    private guide: GuideService,
    private msalBroadcastService: MsalBroadcastService,
    private route: ActivatedRoute,
    private sectionDataService: SectionDataService
  ) {}

  ngOnInit(): void {
    this.hasData = true;
    this.sectionDataService.serviceData
      .pipe(takeUntil(this.destroying$))
      .subscribe((message) => this.selectSectionById(message));

    this.route.params.subscribe((params) => {
      this.guideId = params['id'];

      this.guide?.loadGuide(this.guideId)?.subscribe(
        (data) => {
          this.guideData = data;
          let sectionId = this.guideData.sections[0].guideSectionId;
          this.selectSectionById(sectionId);
        },
        (error) => {
          this.guideData = null;
          this.hasData = false;
          throw error;
        }
      );
    });

    this.msalBroadcastService?.msalSubject$
      ?.pipe(filter((msg: EventMessage) => msg?.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {});
  }

  ngOnDestroy() {
    this.destroying$.complete();
  }

  selectSectionById(selected: number): void {
    if (selected !== -1) {
      this.selectedSectionId = selected;
      this.selectedSection = this.guideData.sections.find((section) => section.guideSectionId === selected);
    }
  }
}
