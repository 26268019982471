import {  Directive,Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SpinnerComponent } from '../component/spinner.component';


@Directive({
  selector: '[loadingSpinner]'
})
export class LoadingSpinnerDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input() set loadingSpinner(condition: boolean) {
      if (!condition) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (condition) {
        this.viewContainer.clear();
        this.viewContainer.createComponent(SpinnerComponent);
      }
  }
}

