<ng-container #notificationContainer *ngIf="open">
  <div class="modal error">
    <span (click)="handleCloseModal()" class="material-icons close">close</span>
    <p class="modal-title">{{ title }}</p>
    <div class="modal-content">
      <div class="modal-message">{{ message }}</div>
      <mat-expansion-panel class="expansion-panel-description" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-description>Technical description</mat-panel-description>
        </mat-expansion-panel-header>
        <div
          matTooltip="Click to copy the error"
          [matTooltipPosition]="'above'"
          (click)="handleCopyToClipboard($event)"
          class="modal-description"
        >
          {{ description }}
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</ng-container>
