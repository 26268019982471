<div cdkDrag>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [ngClass]="componentChangesClass"
    class="ob-expansion"
  >
    <mat-expansion-panel-header>
      <div class="drag-handle" cdkDragHandle>
        <mat-icon aria-hidden="false" aria-label="Drag Handle Icon" fontIcon="drag_handle"></mat-icon>
      </div>
      <mat-panel-title>{{ sectionTitle }}</mat-panel-title>
    </mat-expansion-panel-header>
    <form class="section-form" [formGroup]="sectionForm">
      <mat-form-field class="section-input" appearance="outline">
        <mat-label>Section Name</mat-label>
        <input
          matInput
          id="section-name"
          name="section-name"
          type="text"
          class="large"
          [required]="true"
          formControlName="sectionTitleControl"
          placeholder="Enter Section Title"
        />
      </mat-form-field>

      <mat-form-field class="section-input content" appearance="outline" floatLabel="always">
        <mat-label>Content</mat-label>
        <textarea
          matInput
          placeholder="Angular Material UI Hack for mat-label"
          required
          style="display: none"
        ></textarea>
        <quill-editor
          (onEditorCreated)="onCreated($event)"
          class="content-editor"
          name="editor"
          formControlName="sectionContentControl"
          [readOnly]="disableSection"
        >
        </quill-editor>
        <quill-table-options
          (updateParent)="handleUpdateOptions($event)"
          [editorInstance]="quillInstance"
          [showOptions]="showTableOptions"
        ></quill-table-options>
      </mat-form-field>
      <mat-action-row class="action-row">
        <button mat-stroked-button color="warn" (click)="discardChanges()">
          <mat-icon fontIcon="history"></mat-icon> Discard Changes
        </button>
        <button mat-stroked-button color="warn" (click)="delete()">
          <mat-icon fontIcon="{{ disableSection ? 'undo' : 'delete' }}"></mat-icon>
          {{ disableSection ? 'Undo' : 'Remove' }}
        </button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
</div>
