import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditChecklistComponent } from './edit-checklist/edit-checklist.component';
import { EditChecklistRoutingModule } from './edit-checklist-routing.module';
import { PendingChangesGuard } from '../../guard/pending-changes.guard';

@NgModule({
  declarations: [EditChecklistComponent],
  imports: [CommonModule, EditChecklistRoutingModule],
  exports: [EditChecklistComponent],
  providers: [PendingChangesGuard],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EditChecklistModule { }
