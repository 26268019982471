import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from 'src/app/guard/pending-changes.guard';
import { EditChecklistComponent } from './edit-checklist/edit-checklist.component';

const routes: Routes = [
  { path: '', component: EditChecklistComponent, canDeactivate: [PendingChangesGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditChecklistRoutingModule {}
