import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: '<mat-spinner class="loading-spinner"></mat-spinner>',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  constructor() { }
}
