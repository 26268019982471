import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill';
import { MatButtonModule } from '@angular/material/button';

import { EditRoutingModule } from './edit-routing.module';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { EditViewComponent } from './edit-view/edit-view.component';
import { SectionEditDialogComponent } from './section-edit-dialog/section-edit-dialog.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { ExpansionPanelComponent } from './expansionPanel/expansionPanel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '../shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { PendingChangesGuard } from '../guard/pending-changes.guard';
import { TableOptions } from './expansionPanel/table-options/table-options.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SectionEditDialogComponent,
    ConfirmDeleteDialogComponent,
    EditViewComponent,
    ExpansionPanelComponent,
    TableOptions
  ],
  imports: [
    FormsModule,
    CommonModule,
    QuillModule,
    MatDialogModule,
    MatTooltipModule,
    EditRoutingModule,
    SpinnerModule,
    MatExpansionModule,
    SharedModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    DragDropModule,
    MatIconModule,
    MatBadgeModule
  ],
  exports: [SectionEditDialogComponent, ConfirmDeleteDialogComponent, EditViewComponent],
  providers: [PendingChangesGuard],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EditModule {}
