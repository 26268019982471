import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ErrorDialogService } from './error.dialog.service';
interface ErrorBaseResult {
  message: string;
  description: string;
  details: any;
}

@Injectable()
export class GlobalErrorService implements ErrorHandler {
  constructor(private ntfService: ErrorDialogService, private zone: NgZone) {}

  handleError(error: any) {
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }
    this.zone.run(() => {
      const { message, description, details } = this.getErrorMessage(error);
      this.ntfService.showNotification(message, description, JSON.stringify(details));
    });

    console.table('Error from global error handler', error);
  }
  
  getErrorMessage(httpResponse: HttpErrorResponse): ErrorBaseResult {
    const errorMessage = {} as ErrorBaseResult;

    if (httpResponse?.error) {
      const { message, description } = httpResponse.error;
      errorMessage.message = message ?? httpResponse.message;
      errorMessage.description = description ?? httpResponse.statusText;
    }
    errorMessage.details = {
      error: httpResponse?.error?.detail ?? httpResponse?.error,
      url: httpResponse.url
    };
    return errorMessage;
  }
}
