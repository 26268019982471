import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditViewComponent } from './edit-view/edit-view.component';
import { PendingChangesGuard } from '../guard/pending-changes.guard';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', redirectTo: 'checklist', pathMatch: 'full' },
  { path: 'guides/:id', component: EditViewComponent, canActivate: [MsalGuard], canDeactivate: [PendingChangesGuard] },
  {
    path: 'checklist',
    loadChildren: () => import('./edit-checklist/edit-checklist.module').then(m => m.EditChecklistModule),
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditRoutingModule {}
