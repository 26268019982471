import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { Group } from '../../models/group';
import { GroupDTO } from 'src/app/models/groupDTO';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  constructor(private http: HttpClient) {}

  loadGroups(organizationId: number): Observable<Group[]> {
    let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' });

    return this.http
      .get<GroupDTO[]>(`${environment?.apiEndpoints?.onboardingApi}/v1/organization/${organizationId}/groups`, {
        headers: headers
      })
      .pipe(
        map((groups) => groups.map((group) => ({
          ...group,
          groupId: group.GroupId,
          name: group.Name,
          organizationId: group.OrganizationId
        })))      
      );
  }

  saveGroup(body: Group) {
    let groupDTO: GroupDTO = ({
      GroupId: body.groupId,
      Name: body.name,
      OrganizationId: body.organizationId
    });

    this.http.post(`${environment?.apiEndpoints?.onboardingApi}/v1/group`, groupDTO).subscribe();
  }
}
