import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationsRoutingModule } from './organizations-routing.module';

import { OrganizationsViewComponent } from './organizations-view/organizations-view.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { SpinnerModule } from '../spinner/spinner.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [OrganizationsViewComponent],
  imports: [CommonModule, OrganizationsRoutingModule, MatSidenavModule, MatListModule, SpinnerModule, SharedModule],
  exports: [OrganizationsViewComponent]
})
export class OrganizationsModule { }
