<main class="main-content">
  <ng-container *loadingSpinner="isLoadingData">
    <div>
      <h2>Select group</h2>
    </div>
    <app-link-button-list [entities]="entities" *ngIf="hasData"></app-link-button-list>
    <ng-container *ngIf="!hasData">
      <main class="main-content">
        <h3>Groups not found for OrganizationId: {{organizationId}}</h3>
      </main>
    </ng-container>
  </ng-container>
</main>