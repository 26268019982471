<ng-container *ngIf="hasData">
  <mat-sidenav-container *loadingSpinner="!guideData">
    <mat-sidenav #sidenav opened mode="side">
      <mat-nav-list>
        <mat-list-item
          *ngFor="let section of guideData?.sections"
          [ngClass]="{ active: section.guideSectionId === selectedSectionId }"
          class="list-item"
          (click)="selectSectionById(section.guideSectionId)"
        >
          <span matListItemTitle>
            {{ section.title }}
          </span>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <app-section *ngIf="selectedSection" [displaySection]="selectedSection"></app-section>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
<ng-container *ngIf="!hasData">
  <main class="main-content">
    <h3>Guide data not found for GuideId: {{ guideId }}</h3>
  </main>
</ng-container>