export const environment = {
  production: false,

  redirectUri: '#{redirectUri}#', // Redirect after login
  msalInterceptor: '#{msalInterceptor}#', // MSAL Interceptor Configuration
  appRegistrationUri: '#{appRegistrationUri}#', // Application (client) ID from the app registration
  azureCloudAuthority: '#{azureCloudAuthority}#', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)

  apiEndpoints: {
    onboardingApi: '#{onboardingApi}#'
  }
};
