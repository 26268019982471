import { Component, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/guard/pending-changes.guard';

@Component({
  selector: 'app-edit-checklist',
  templateUrl: './edit-checklist.component.html',
  styleUrls: ['./edit-checklist.component.scss']
})
export class EditChecklistComponent implements OnInit, ComponentCanDeactivate {
  public unsavedChanges: boolean = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // If there are unsaved changes, then the page should trigger a message so canDeactivate is false.
    return !this.unsavedChanges;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
