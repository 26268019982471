import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion'
import { HeaderComponent } from './components/header/header.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { LinkButtonListComponent } from './components/link-button-list/link-button-list.component';
import { MarkAsteriskDirective } from './directives/mark-asterisk.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { NotificationComponent } from './components/notification/notification.component';
import { GlobalErrorService } from './services/global.error.service';

@NgModule({
  declarations: [
    MarkAsteriskDirective,
    HeaderComponent,
    MenuBarComponent,
    SafeHtmlPipe,
    LinkButtonListComponent,
    SearchResultsComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatToolbarModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatExpansionModule
  ],
  exports: [
    MarkAsteriskDirective,
    NotificationComponent,
    HeaderComponent,
    MenuBarComponent,
    SafeHtmlPipe,
    LinkButtonListComponent
  ],
  providers: [GlobalErrorService]
})
export class SharedModule {}
