import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupsRoutingModule } from './groups-routing.module';

import { GroupsViewComponent } from './groups-view/groups-view.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { SpinnerModule } from '../spinner/spinner.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [GroupsViewComponent],
  imports: [CommonModule, GroupsRoutingModule, MatSidenavModule, MatListModule, SpinnerModule, SharedModule],
  exports: [GroupsViewComponent]
})
export class GroupsModule {}
