import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';

import { GroupsModule } from './groups/groups.module';
import { GuideModule } from './guide/guide.module';
import { SharedModule } from './shared/shared.module';
import { SectionDataService } from './service/SectionDataService.service';
import { GlobalErrorService } from './shared/services/global.error.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        SharedModule,
        GroupsModule,
        GuideModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.appRegistrationUri, // Application (client) ID from the app registration
                authority: environment.azureCloudAuthority, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
                redirectUri: environment.redirectUri // This is your redirect URI
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE // Set to true for Internet Explorer 11
            }
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: [`${environment.appRegistrationUri}/user_impersonation`]
            }
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                [environment.msalInterceptor, [`${environment.appRegistrationUri}/user_impersonation`]]
            ])
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorService
        },
        MsalGuard,
        SectionDataService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
