import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GuideRoutingModule } from './guide-routing.module';

import { SectionComponent } from './section/section.component';
import { GuideViewComponent } from './guide-view/guide-view.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { SpinnerModule } from '../spinner/spinner.module';
import { SharedModule } from '../shared/shared.module';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [SectionComponent, GuideViewComponent],
  imports: [
    CommonModule,
    GuideRoutingModule,
    MatSidenavModule,
    MatListModule,
    SpinnerModule,
    SharedModule,
    QuillModule.forRoot({
      modules: {
        table: true
      }
    })
  ],
  exports: [SectionComponent, GuideViewComponent]
})
export class GuideModule {}
