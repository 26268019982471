<ul class="link-button-list">
  <li class="link-button-list-item" *ngFor="let entity of entities">
    <a mat-raised-button class="link-button" [routerLink]="entity.route">
      {{ entity.name }}
    </a>
  </li>
  <!-- This section is meant to support some sort of Add button functiontionality. It should be secured to Super Admin or Admin only when permissions are introduced. -->
  <!-- <ng-container *ngIf="true">
    
  </ng-container> -->
</ul>