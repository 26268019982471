import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface INotification {
  message: string;
  title: string;
  description?: string;
}
@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  private notification$: Subject<INotification> = new BehaviorSubject(null);
  constructor() {}

  showNotification(title: string, message: string, description?: string) {
    this.notification$.next({
      message,
      title,
      description
    } as INotification);
  }

  get notification() {
    return this.notification$.asObservable();
  }
}
