import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { QuillEditor, TableModule } from '../quillTypes';

@Component({
  selector: 'quill-table-options',
  templateUrl: './table-options.component.html',
  styleUrls: ['./table-options.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableOptions implements OnChanges {
  @Input() showOptions = false;
  @Input() editorInstance: QuillEditor = undefined;
  @Output() updateParent = new EventEmitter<boolean>();
  tableModule: TableModule = undefined;
  ngOnChanges(_: SimpleChanges): void {
    if (this.editorInstance) {
      this.tableModule = this.editorInstance.getModule<TableModule>('table');
    }
  }

  onClose() {
    this.updateParent.emit(false);
  }
}
