import { Component, Input, OnInit } from '@angular/core';
import { EntityData } from 'src/app/models/entityData';
@Component({
  selector: 'app-link-button-list',
  templateUrl: './link-button-list.component.html',
  styleUrls: ['./link-button-list.component.scss'],
})
export class LinkButtonListComponent {
  @Input() entities: EntityData[];
  // constructor(entities: EntityData[]) { 
  //   this.entities = entities;
  // }
  // constructor(){}
  // ngOnInit(): void {
  // }
}